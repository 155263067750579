import 'normalize.css';
import '@styles/global.scss';
import '@styles/fonts.scss';
import '@styles/prismjs.scss';
import './src/styles/global.css';

import sal from 'sal.js';
import { matchesPatterns } from '@src/utils/utils';

const noScrollPathnames = [
  /\/blog\/(\d+\/)*$/,
  /\/blog\/highlights\/(\d+\/)*$/,
  /\/blog\/business-insights\/(\d+\/)*$/,
  /\/blog\/deep-dive\/(\d+\/)*$/,
];

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const { location: prevLocation } = prevRouterProps || {};
  const { pathname: prevPathName } = prevLocation || {};

  const {
    location: { search, pathname, state },
  } = routerProps;

  const { preventScroll } = state || {};

  // Prevent scroll update while navigating in the blog context
  // Leave navigating to blog from other pages unaffected
  if (matchesPatterns(noScrollPathnames, pathname) && matchesPatterns(noScrollPathnames, prevPathName)) {
    document.body.parentNode.style.scrollBehavior = 'auto';
    return false;
  }

  // Next line prevents gatsby scrolling to top when changing pages in pagination component
  if (search?.includes('?page=')) {
    return false;
  }

  document.body.parentNode.style.scrollBehavior = preventScroll ? 'auto' : 'smooth';
  return true;
};

export const onInitialClientRender = () => {
  sal();
};
